import React from 'react';
import Link from 'next/link';
import styles from './style.module.scss'; // Adjust the path as necessary
import Image from 'next/image';
import { useZendesk } from 'react-use-zendesk';

const FooterMenu: React.FC = () => {
  const { open } = useZendesk();
  const data = [
    {
      title: 'Our Company',
      links: [
        { text: 'About Us', href: '/about-us' },
        { text: 'Career', href: '/careers' },
        { text: 'Media', href: '/media' },
        { text: 'Blog', href: '/blog/' },
        { text: 'Customer Stories', href: '/customer-stories' },
        { text: 'Our Stores', href: '/offline-furniture-store' },
      ],
    },
    {
      title: 'Useful Links',
      links: [
        { text: 'Custom Furniture', href: '/custom-furniture' },
        { text: 'Exporters', href: '/furniture-exporters' },
        { text: 'Buy in Bulk', href: '/furniture-packages' },
        { text: 'Hotel Furniture', href: '/hotel-furniture' },
        { text: 'Delivery Location', href: '/locations' },
        { text: 'Sitemap', href: '/sitemap' },
      ],
    },
    {
      title: 'Shop By Room',
      links: [
        { text: 'Living Room', href: '/living-room-furniture' },
        { text: 'Bedroom', href: '/bedroom-furniture' },
        { text: 'Dining Room', href: '/dining-room-furniture' },
        { text: 'Kids Room', href: '/kids-furniture' },
        { text: 'Modular Furniture', href: '/modular-furniture' },
        { text: 'Modular Kitchen', href: '/modular-kitchen-designs' },
        { text: 'Modular Wardrobe', href: '/modular-wardrobe-designs' },
      ],
    },
    {
      title: 'Partners',
      links: [
        { text: 'Become a Franchise', href: '/furniture-franchise' },
        { text: 'Sell on WoodenStreet', href: '/sell-on-woodenstreet' },
        {
          text: 'Design Partner',
          href: '/design-services/partner-to-win?architect-interior_menu',
        },
      ],
      extraSection: {
        title: 'Trusted By',
        links: [
          {
            text: 'QRO Certified',
            href: '/footer/QRO-doc.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
          {
            text: 'Ukcert Certified',
            href: '/footer/Ukcert-doc.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
        images: [
          {
            src: '/images/footer/Qro-logo.png',
            alt: 'Qro-logo',
            href: '/footer/QRO-doc.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
          {
            src: '/images/footer/Ukcert-logo.png',
            alt: 'Ukcert-logo',
            href: '/footer/Ukcert-doc.pdf',
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
      },
    },
    {
      title: 'Need Help',
      links: [
        { text: 'Help Center', href: '/help-center' },
        { text: 'Contact Us', href: '/support-form' },
        {
          text: 'Ask Experts',
          href: '/',
          handleClick: (e: any) => {
            e.preventDefault(), open();
          },
        },
        { text: 'Track your order', href: '/track-your-order' },
      ],
    },
  ];

  return (
    <div className="wdRow wdRow-cols-2 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-5">
      {data.map((menu, index) => (
        <div key={index} className="wdCol">
          <ul className={styles.footermenu}>
            <li>
              <h3>{menu.title}</h3>
            </li>
            {menu.links.map((link: any, linkIndex: number) => (
              <li key={linkIndex}>
                <Link
                  href={link.href}
                  className={styles.footermenuLink}
                  onClick={(e) => link?.handleClick && link.handleClick(e)}
                >
                  {link.text}
                </Link>
              </li>
            ))}

            {menu.extraSection && (
              <>
                <li style={{ marginTop: '20px', marginBottom: '10px' }}>
                  <h3>{menu.extraSection.title}</h3>
                </li>

                {menu.extraSection.links.map((link: any, linkIndex: number) => (
                  <li key={linkIndex}>
                    <Link
                      href={link.href}
                      className={styles.footermenuLink}
                      target={link.target}
                      rel={link.rel}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}

                {menu.extraSection.images.map(
                  (link: any, linkIndex: number) => (
                    <li
                      key={linkIndex}
                      style={{
                        display: 'inline-block',
                        paddingRight: '10px',
                        marginTop: '10px',
                      }}
                    >
                      <Link
                        href={link.href}
                        className={styles.footermenuImageLink}
                        target={link.target}
                        rel={link.rel}
                      >
                        <Image
                          src={link.src}
                          alt={link.alt}
                          style={link.style}
                          height={47}
                          width={55}
                          quality={75}
                          loading="lazy"
                        />
                      </Link>
                    </li>
                  )
                )}
              </>
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FooterMenu;
